import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";

import sydneyHeraldImage from "images/asseen_sydney_herald.svg";
import sydneyHeraldImageNoOpacity from "images/asseen_sydney_herald_no_opacity.svg";
import theAgeImage from "images/asseen_the_age.svg";
import theAgeImageNoOpacity from "images/asseen_the_age_no_opacity.svg";
import vogueImage from "images/asseen_Vogue.svg";

const data = [
  {
    img: vogueImage,
    content:
      "“We know where we’ll be looking for a designer dress for our next silly season soirée.”",
  },
  {
    img: sydneyHeraldImage,
    content: "“The airbnb of fashion.”",
  },
  {
    img: theAgeImage,
    content: "“Access is the new ownership.”",
  },
];

export const AsSeenIn: React.FC = () => {
  const [activeItem, setActiveItem] = useState(0);

  useEffect(() => {
    const switchActiveItem = setInterval(
      () => setActiveItem((_activeItem) => (_activeItem + 1) % 3),
      3000
    );
    return () => {
      clearInterval(switchActiveItem);
    };
  }, []);

  return (
    <Box
      maxWidth={["22rem", null, null, "40rem"]}
      textAlign="center"
      py={["4rem", null, null, "6rem"]}
    >
      <Box fontSize={["1rem", null, null, "1.1rem"]}>AS SEEN IN</Box>
      <Box
        fontSize={["1rem", null, null, "2rem"]}
        mt="1rem"
        textTransform="uppercase"
        fontWeight="600"
        lineHeight={["1rem", null, null, "2rem"]}
        px={["0.53rem", "0"]}
        minHeight="100px"
        alignItems="center"
        display="flex"
        justifyContent="center"
      >
        {data?.[activeItem]?.content}
      </Box>
      <Box
        display="flex"
        w="full"
        justifyContent="space-between"
        mt="3rem"
        minWidth={["340px", null, null, "640px"]}
      >
        <Box
          color={activeItem === 0 ? "black" : "rgba(0,0,0, 0.3)"}
          w={["6.5rem", null, null, "12rem"]}
          h={["4rem", null, null, "5.5rem"]}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <ReactSVG src={vogueImage.src} />
        </Box>
        <Box
          w={["6.5rem", null, null, "12rem"]}
          h={["4rem", null, null, "5.5rem"]}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <ReactSVG
            src={
              activeItem === 1
                ? sydneyHeraldImageNoOpacity.src
                : sydneyHeraldImage.src
            }
          />
        </Box>
        <Box
          w={["6.5rem", null, null, "12rem"]}
          h={["4rem", null, null, "5.5rem"]}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <ReactSVG
            src={activeItem === 2 ? theAgeImageNoOpacity.src : theAgeImage.src}
          />
        </Box>
      </Box>
    </Box>
  );
};
